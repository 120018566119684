<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>
import TimeAgo from 'vue2-timeago';
import VueSlideBar from "vue-slide-bar";
import { HalfCircleSpinner } from 'epic-spinners';

import {get_acsrf_token, startsWith} from "@/methods";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import CopyableInput from "@/components/CopyableInput";
import {mapGetters} from "vuex";
import {BucketType} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput,
    VueSlideBar,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    TimeAgo
  },
  validations: {

  },
  methods: {
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/queryBucket`);
      url.searchParams.append('bucket_type', this.bucketType);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('category', this.category);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy,
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            } else if(res.status === 204) {
              this.available = false;
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10);
            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            if(this.available) this.$toast.error('Failed to contact query service');
            if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.adhocSearch();
    },
    async onCategoryChange() {
      this.currentPage = 1;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    },
    async openSettings(event) {
      this.$emit('tabChangeRequest', 'DSettings');
      event.preventDefault();
    },
    getRouteForBucketType() {
      switch(this.bucketType) {
        case BucketType.WHITELIST: {
          return 'whitelist';
        }
        case BucketType.RESERVED_SLOT: {
          return 'reservedslot';
        }
        case BucketType.QUEUE_PRIORITY: {
          return 'queuepriority';
        }
        case BucketType.NAME_FILTER: {
          return 'namefilter';
        }
        case BucketType.CHAT_FILTER: {
          return 'chatfilter';
        }
      }
    },
    async deleteEntry(cftools_id) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id
        };

        let route = this.getRouteForBucketType();
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${cftools_id}/${route}`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('server.view.bucket.remove.success')
          });
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    positionQuery: {
      get() {
        return `${this.query.position.coordinates[0]}, ${this.query.position.coordinates[1]}`;
      },
      set(val) {
        let f = val.match('^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$');
        if(f === null) return this.$refs.positionQueryInput.$el.classList.add('is-invalid');
        else this.$refs.positionQueryInput.$el.classList.remove('is-invalid');
        let x = f[1];
        let y = f[3];
        this.query.position.coordinates = [x, y];
      }
    },
    customFieldLabel() {
      return this.$t(`server.view.players.columns.custom.${this.query.customField}`);
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        });
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  mounted() {
    this.fetchData().catch(error => {
      console.error(error);
    });
  },
  created() {
    this.categories.push({value: 'all', text: this.$t('components.table.filters.categories.all')});
    this.categories.push({value: 'temporary', text: this.$t('components.table.filters.categories.temporary')});
    this.categories.push({value: 'permanent', text: this.$t('components.table.filters.categories.permanent')});
  },
  data() {
    return {
      ready: false,
      available: true,
      error: false,
      items: [],
      bucketType: BucketType.QUEUE_PRIORITY,
      fields: [
        {
          key: 'user.cftools_id',
          label: this.$t('server.view.bucket.columns.player'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('server.view.bucket.columns.created'),
          sortable: true
        },
        {
          key: 'creator.cftools_id',
          label: this.$t('server.view.bucket.columns.admin'),
          sortable: true
        },
        {
          key: 'meta.expiration',
          label: this.$t('server.view.bucket.columns.expiration'),
          sortable: true
        },
        {
          key: 'meta.comment',
          label: this.$t('server.view.bucket.columns.comment'),
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,
      category: 'all',
      categories: [],
      // Query
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        expiration: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: false,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      }
    }
  }
};
</script>

<template>
  <div v-if="available">
    <div class="alert alert-info text-center">
      <span class="font-size-16">
        {{ $t('server.view.queuepriority.disclaimer') }}
      </span>
    </div>
    <div class="card" >
      <div class="card-body">
        <div class="c-table-header">
          <div class="c-table-title" v-on:click="filtersToggle()">
            <span class="badge badge-primary">
              <h6 class="mb-0 mt-0">
                {{ $t('components.table.filters.title') }}
                <small>
                  {{ $t('components.table.filters.details') }}
                </small>
              </h6>

            </span>
          </div>
          <!-- Primary search term -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row">
            <div class="col-lg col-sm-8">
              <div class="mt-lg-2">
                {{$t('components.table.filters.search_term')}}
                <span class="badge badge-primary mb-1 c-table-st-clear" v-on:click="clearSearchTerm()">
                  {{ $t('components.table.filters.clear') }}
                </span>
              </div>
              <b-form-input
                  for="text"
                  v-model="query.search_term"
                  :placeholder="$t('components.table.filters.search_term')"
                  v-on:keyup="$event.keyCode === 13 ? fetchData() : null"
              />
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="mt-lg-2">
                {{$t('components.table.filters.category')}}
              </div>
              <b-form-select class="form-control" v-model="category" :options="categories" v-on:change="onCategoryChange()"></b-form-select>
            </div>
          </div>
          <div class="c-table-exfilters" v-if="query.full">
            <!-- Date range -->
            <div class="c-table-spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.date.start.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('server.view.players.created.after') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                        id="date-time"
                        v-model="query.date.start.value"
                        type="datetime-local"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="c-table-sm-spacer"/> <!-- Spacer -->
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                      switch
                      v-model="query.date.end.enabled"
                      class="mt-lg-2"
                    >
                      {{ $t('server.view.players.created.before') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                      id="date-time"
                      v-model="query.date.end.value"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- expiration range -->
            <div class="c-table-spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.expiration.start.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('server.view.bucket.expiration.after') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                        id="date-time"
                        v-model="query.expiration.start.value"
                        type="datetime-local"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="c-table-sm-spacer"/> <!-- Spacer -->
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.expiration.end.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('server.view.bucket.expiration.before') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                        id="date-time"
                        v-model="query.expiration.end.value"
                        type="datetime-local"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Special features, GEO etc. -->
            <template v-if="query.position.available">
            <div class="c-table-spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.position.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('components.table.filters.pos.coords') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <div class="input-group">
                      <b-form-input
                          v-model="positionQuery"
                          ref="positionQueryInput"
                      />
                      <div class="invalid-feedback">
                        <span>
                          {{ $t('components.table.filters.pos.error') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <div class="mt-lg-2">
                      {{ $t('components.table.filters.pos.radius') }}
                    </div>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <vue-slide-bar v-model="query.position.radius" :min="0" :max="1000" class="mt-n2" />
                  </div>
                </div>
              </div>
            </div>
            </template>
          </div>
          <!-- Button -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12">
              <button class="btn btn-primary btn-block" v-on:click="adhocSearch()">
                <i class="fad fa-search"></i>
                {{ $t('components.table.filters.search') }}
              </button>
            </div>
          </div>
          <div class="float-right" v-if="query.delta > 0">
            <small>Query duration {{ query.delta }}ms</small>
          </div>
        </div>
        <div class="mt-2">
          <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
              </div>
            </div>
          </div>
          <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
            <template #empty="scope">
              <h4>{{ $t('components.table.filters.empty') }}</h4>
            </template>

            <template #cell(user.cftools_id)="data">
              <template v-if="data.item.user.cftools_id">
                <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}">
                  <img
                      :src="data.item.user.avatar"
                      alt="profile picture"
                      class="rounded-circle avatar-xs"
                      v-if="data.item.user.avatar"
                  >
                  {{ data.item.user.display_name }}
                </router-link>
              </template>
              <template v-else>
                <i>{{ $t('server.view.protocol.no_user') }}</i>
              </template>
            </template>

            <template #cell(creator.cftools_id)="data">
              <template v-if="data.item.creator.cftools_id">
                <router-link :to="{name: 'profile', params: {cftools_id: data.item.creator.cftools_id}}">
                  <img
                      :src="data.item.creator.avatar"
                      alt="profile picture"
                      class="rounded-circle avatar-xs"
                  >
                  {{ data.item.creator.display_name }}
                </router-link>
              </template>
              <template v-else>
                <i>{{ $t('server.view.protocol.no_user') }}</i>
              </template>
            </template>

            <template #cell(meta.expiration)="data">
              <template v-if="data.item.meta.expiration !== null">
                {{ $d(parseDate(data.item.meta.expiration), 'datetime', getDTLocale()) }}
              </template>
              <template v-else>
                {{ $t('server.view.bucket.never') }}
              </template>
            </template>

            <template #cell(meta.comment)="data">
              {{ data.item.meta.comment }}
            </template>

            <template #cell(actions)="data">
              <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}" target="_blank">
                <button class="btn btn-sm btn-primary">
                  <i class="fad fa-info" />
                  {{ $t('server.view.bucket.profile') }}
                </button>
              </router-link>

              <button class="btn btn-sm btn-danger m-1" v-on:click="deleteEntry(data.item.user.cftools_id)">
                <i class="far fa-trash-alt" />
              </button>
            </template>

            <template #cell(created_at)="data">
              {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
            </template>

          </b-table>
        </div>
        <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col justify-content-center">
        <div  class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <i class="fad fa-empty-set info-component-icon"></i>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-12">
              <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('server.view.queuepriority.empty.title')}}</h3>
              <h5 class="text-muted">{{$t('server.view.queuepriority.empty.description')}}</h5>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col">
              <a href="#" v-on:click="openSettings($event)">
                <button class="btn btn-primary btn-lg text-uppercase">
                  <h5 class="mb-0">
                    {{$t('server.view.queuepriority.empty.button')}}
                  </h5>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
